<template>
  <div class="expert_page">
    <div class="title">全国幼儿足球专家委员会名录</div>
    <div class="list">
      <div class="left">
        <el-table
          stripe
          header-cell-class-name="header_row_style"
          :data="tbaleList1"
          style="width: 100%"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :width="item.width"
            :label="item.label"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="right">
        <el-table
          stripe
          header-cell-class-name="header_row_style"
          :data="tbaleList2"
          style="width: 100%"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :width="item.width"
            :label="item.label"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'expertBlock',
  components: {},
  data() {
    return {
      columns: [
        {
          label: '姓名',
          prop: 'name',
        },
        {
          label: '职务',
          prop: 'duty',
        },
      ],
      tbaleList1: [
        { name: '刘志云', duty: '主任委员' },
        { name: '王凯珍', duty: '副主任委员、第1组组长' },
        { name: '张守伟', duty: '副主任委员、第9组组长' },
        { name: '马玉华', duty: '副主任委员、第3组组长' },
        { name: '方雨', duty: '秘书长' },
        { name: '朱红松', duty: '副秘书长、行政咨询' },
        { name: '冯强', duty: '副秘书长、第11组组长' },
        { name: '周毅', duty: '副秘书长、第5组组长' },
        { name: '侯大伟', duty: '副秘书长、第8组组长' },
        { name: '秦旸', duty: '专家委员、行政咨询' },
        { name: '蒙延荣', duty: '专家委员、行政咨询' },
        { name: '刘沙', duty: '推广咨询' },
        { name: '李雷', duty: '推广咨询' },
        { name: '范楷茗', duty: '推广咨询' },
        { name: '朱莉', duty: '宣传咨询' },
        { name: '陈慈慈', duty: '宣传咨询' },
        { name: '崔知忆', duty: '宣传咨询' },
        { name: '魏俊玲', duty: '专家委员、康和丽舍幼儿园园长' },
        { name: '汪晓赞', duty: '专家委员、第2组组长' },
        { name: '马瑞', duty: '专家委员' },
        { name: '邵斌', duty: '专家委员' },
        { name: '吴金贵', duty: '专家委员' },
        { name: '曹振波', duty: '专家委员' },
        { name: '刘越', duty: '专家委员' },
        { name: '姚波', duty: '专家委员' },
        { name: '陆伟', duty: '专家委员' },
        { name: '刘伟', duty: '专家委员' },
        { name: '冯蕴中', duty: '专家委员' },
        { name: '田立山', duty: '专家委员、第4组组长' },
        { name: '张建中', duty: '专家委员' },
        { name: '李晓峰', duty: '专家委员' },
        { name: '余卫东', duty: '专家委员' },
        { name: '庄弼', duty: '专家委员' },
        { name: '徐云燕', duty: '专家委员' },
        { name: '赵刚', duty: '专家委员' },
        { name: '李斌', duty: '专家委员' },
        { name: '林金标', duty: '专家委员' },
      ],
      tbaleList2: [
        { name: '邱云', duty: '专家委员、第6组组长' },
        { name: '赵超君', duty: '专家委员' },
        { name: '席海龙', duty: '专家委员' },
        { name: '张龙', duty: '专家委员' },
        { name: '沈国征', duty: '专家委员' },
        { name: '邓世俊', duty: '专家委员、第5组组长' },
        { name: '张小波', duty: '专家委员' },
        { name: '熊伟', duty: '专家委员' },
        { name: '陈捷', duty: '专家委员' },
        { name: '张宇', duty: '专家委员' },
        { name: '王鹏', duty: '专家委员' },
        { name: '张建伟', duty: '专家委员' },
        { name: '牛锦山', duty: '专家委员' },
        { name: '谭勇', duty: '专家委员' },
        { name: '王德周', duty: '专家委员' },
        { name: '齐小平', duty: '专家委员' },
        { name: '王海', duty: '专家委员' },
        { name: '裴海泓', duty: '专家委员' },
        { name: '于泉海', duty: '专家委员' },
        { name: '张亮', duty: '专家委员' },
        { name: '崔继红', duty: '专家委员' },
        { name: '韩端', duty: '专家委员' },
        { name: '王占国', duty: '专家委员' },
        { name: '赵文彤', duty: '专家委员、第10组组长' },
        { name: '王斌', duty: '专家委员' },
        { name: '翟建东', duty: '专家委员' },
        { name: '王润平', duty: '专家委员' },
        { name: '成功', duty: '专家委员' },
        { name: '聂真新', duty: '专家委员' },
        { name: '苏祝捷', duty: '专家委员' },
        { name: '黄杰聪', duty: '专家委员' },
        { name: '兰政', duty: '专家委员' },
        { name: '刘强', duty: '专家委员' },
        { name: '马业康', duty: '专家委员、第12组组长' },
        { name: '李旭天', duty: '专家委员' },
        { name: '杨建', duty: '专家委员' },
        { name: '胡双庆', duty: '专家委员' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.expert_page {
  .title {
    width: 65%;
    background-color: #cc1a30;
    color: #fcfefb;
    font-size: 40px;
    margin: 0 auto;
    height: 260px;
    padding-left: 76px;
    box-sizing: border-box;
    line-height: 260px;
    font-weight: bold;
    text-align: left;
  }
  .list {
    width: 65%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    @include flex(row, space-between, flex-start);
    .right,
    .left {
      width: 40%;
    }
  }
}
</style>
